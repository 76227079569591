<template>
  <div class="w-full flex flex-row" style="justify-content: space-between">
    <ul class="flex flex-row">
      <li
        class="p-1 rounded-full cursor-pointer"
        @click="first">
        <i class="text-1xl bx bx-chevrons-left" />
      </li>
      <li
        class="p-1 rounded-full cursor-pointer"
        @click="back">
        <i class="text-1xl bx bx-chevron-left" />
      </li>
      <li v-for="page in pages" :key="page.name">
        <button
          class="p-1 rounded-full cursor-pointer"
          @click="onPageClick(page.name)"
          :disabled="page.disabled"
          :class="{ 'bg-purple-400 text-white': isPageActive(page.name) }">
          <span class="text-1xl">{{ page.name }}</span>
        </button>
      </li>
      <li
        class="p-1 rounded-full cursor-pointer"
        @click="next">
        <i class="text-1xl bx bx-chevron-right" />
      </li>
      <li
        class="p-1 rounded-full cursor-pointer"
        @click="last">
        <i class="text-1xl bx bx-chevrons-right" />
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Pagination',
  props: {
    pagination: {
      type: Object,
      required: true,
    },
    max: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    startPage() {
      if (this.pagination.currentPage === 1) {
        return 1;
      }
      if (this.pagination.currentPage === this.pagination.totalPages) {
        return this.pagination.totalPages - this.max;
      }
      return this.pagination.currentPage - 1;
    },
    pages() {
      const range = [];
      for (let i = this.startPage || 1; i <= Math.min(this.startPage + this.max, this.pagination.totalPages); i++) {
        range.push({ name: i, disabled: i === this.pagination.currentPage });
      }
      return range;
    },
  },
  methods: {
    first() {
      this.$emit('onPaginationChange', { ...this.pagination, currentPage: 1 });
    },
    back() {
      if (this.pagination.currentPage - 1 <= 0) return
      this.$emit('onPaginationChange', { ...this.pagination, currentPage: this.pagination.currentPage - 1 });
    },
    next() {
      if (this.pagination.currentPage + 1 > this.pagination.totalPages) return;
      this.$emit('onPaginationChange', { ...this.pagination, currentPage : this.pagination.currentPage + 1 });
    },
    last() {
      this.$emit('onPaginationChange', { ...this.pagination, currentPage: this.pagination.totalPages });
    },
    onPageClick(page) {
      this.$emit('onPaginationChange', { ...this.pagination, currentPage: page });
    },
    isPageActive(currentPage) {
      return this.pagination.currentPage === currentPage;
    },
  }
}
</script>
